import { Divider, makeStyles, Typography } from '@material-ui/core';
import polyBeach from '../../images/polyBeach.png';
import React from 'react';
import { OverridableSafeAreaDiv } from './overridableSafeAreaDiv';
import { CenterScreenView } from './CenterScreenView';
import { useParams } from 'react-router-dom';
import { BrandedLink } from '../components/BrandedLink';
import { ThemeWithOrgPalette } from '../../apps/config';

export interface UnauthenticatedSuccessRouteParams {
    successType: 'forgotPassword' | 'parrotPass' | 'parrotPFP',
    externalPropsLobby: 'true'
}

const useStyles = makeStyles((theme: ThemeWithOrgPalette) => ({
    container: {
        color: theme.orgPalette?.darkBackgroundText
    }
}));

export function UnauthenticatedSuccess(): JSX.Element {
    const routeParams = useParams<UnauthenticatedSuccessRouteParams>();
    const externalPropsLobby = routeParams.externalPropsLobby === 'true';
    const style = useStyles();
    let message = 'Congratulations!';
    let explanationContent = <>
        <Typography variant={'h6'}>View your ticket in <BrandedLink to={'/mycontests/upcoming'} text={'My Contests'} /></Typography>
        <Typography variant={'h6'}>- or -</Typography>
        <Typography variant={'h6'}>Purchase more tickets in the <BrandedLink to={'/props/lobby'} text={'Game Lobby'} /></Typography>
    </>;
    switch (routeParams.successType) {
        case 'parrotPFP' :
            message = 'You\'ve minted a Parrot!';
            explanationContent = <Typography variant={'h6'}>Your parrot will be revealed tonight. In the mean time play some games in our <BrandedLink
                to={externalPropsLobby ? { pathname: 'https://games.parrotSocial.club' } : '/props/lobby'}
                target={externalPropsLobby ? '_blank' : undefined}
                text={'Game Lobby'} />!</Typography>;
            break;
        case 'parrotPass': 
            message = 'You\'ve minted a Parrot Pass!';
            explanationContent = <Typography variant={'h6'}>You can now enter Parrot Pass required events in the <BrandedLink
                to={externalPropsLobby ? { pathname: 'https://games.parrotSocial.club' } : '/props/lobby'}
                target={externalPropsLobby ? '_blank' : undefined}
                text={'Game Lobby'} />!</Typography>;
            break;
        case 'forgotPassword':
            message = 'Check your email!';
            explanationContent = <Typography variant={'h6'}>We&apos;ve sent you the steps to change your password.</Typography>;
            break;
    }

    return (
        <OverridableSafeAreaDiv imageSrc={polyBeach} className={ style.container }>
            <CenterScreenView fullHeight>
                <Typography variant={'h4'}>{ message }</Typography>
                <Divider />
                {
                    explanationContent
                }
            </CenterScreenView>
        </OverridableSafeAreaDiv>
    );
}
