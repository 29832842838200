import { HomeScreen } from '../../home';
import MintScreen from '../../mint';
import { AppConfig } from '../config';
import { theme } from './theme';
import { UnauthenticatedSuccess } from '../../utils/views/unauthenticatedSuccess';

export const config: Partial<AppConfig> = {
    dynamicUserflow: {
        parrotPassPurchaseDestination: '/uSuccess/parrotPass/true',
        parrotPFPPurchaseDestination: '/uSuccess/parrotPFP/true'
    },
    navigation: {
        routes: [
            {
                component: HomeScreen,
                default: true,
                exact: true,
                navigation: {
                    label: 'Home',
                    type: 'topNavigation'
                },
                path: '/#home',
                unauthenticated: true
            },
            {
                component: HomeScreen,
                exact: true,
                navigation: {
                    label: 'Parrot Pass',
                    type: 'topNavigation'
                },
                path: '/#parrot-pass',
                unauthenticated: true
            },
            {
                component: HomeScreen,
                exact: true,
                navigation: {
                    label: 'Road Map',
                    type: 'topNavigation'
                },
                path: '/#road-map',
                unauthenticated: true
            },
            {
                component: HomeScreen,
                exact: true,
                navigation: {
                    label: 'Team',
                    type: 'topNavigation'
                },
                path: '/#team',
                unauthenticated: true
            },
            {
                external: true,
                navigation: {
                    label: 'Games Room',
                    type: 'topNavigation'
                },
                path: process.env.REACT_APP_GAMES_ROOM_HOST ?? 'https://games.qa.parrotsocial.club',
                unauthenticated: true
            },
            {
                component: MintScreen,
                navigation: {
                    label: 'Mint',
                    type: 'topNavigation'
                },
                path: '/mint',
                unauthenticated: true
            },
            {
                authenticated: true,
                component: UnauthenticatedSuccess,
                exact: true,
                icon: 'thumbs_up_down',
                path: '/uSuccess/:successType/:externalPropsLobby',
                unauthenticated: true
            }
        ]
    },
    scoringLabel: 'pts',
    tokenShorthand: 'psc',
    theme
};
